<template>
  <b-modal
    id="iban-modal-id"
    hide-header
    hide-footer
    hide-header-close
    body-bg-variant="gray-lighten"
  >
    <validation-observer
      ref="stripeObserver"
      v-slot="{ invalid }"
      slim
    >
      <b-row>
        <b-col align="center">
          <div class="modal-title-class">
            <label class="text-green-dark">
              {{ $t('views.client.details.body.left.subscription-affect.iban-add') }}
            </label>
          </div>
        </b-col>
      </b-row>
      <div class="border-bottom-grey mb-3"/>
      <b-row>
        <b-col cols="6">
          <label class="col-form-label-sm field-name-class">
            {{ $t('views.client.details.body.left.subscription-affect.iban-full-name') }}
          </label>
        </b-col>
        <b-col cols="6">
          <label class="col-form-label-sm field-name-class">
            {{ $t('views.client.details.body.left.subscription-affect.iban-email') }}
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <d-text-field
            v-model="IbanFullName"
            :rules="validation.IbanFullName"
            class-name="background-light-blue-inputs"
            size="sm"
            place-holder="views.client.details.body.left.subscription-affect.iban-full-name-placeholder"
            error-text="validation.required"
          />
        </b-col>
        <b-col cols="6">
          <d-text-field
            v-model="IbanEmail"
            :rules="validation.IbanEmail"
            size="sm"
            class-name="background-light-blue-inputs"
            place-holder="views.client.details.body.left.subscription-affect.iban-email-placeholder"
            error-text="validation.email"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="2">
          <label class="col-form-label-sm field-name-class">
            {{ $t('views.client.details.body.left.subscription-affect.iban-label') }}
          </label>
        </b-col>
        <b-col>
          <stripe-element
            :stripe="stripeKey"
            :el-options="ibanOptions"
            :stripe-options="{ 'stripeAccount': stripeAccountReference }"
            type="iban"
            class="stripe-element background-light-blue-inputs"
            @change="ibcompleted = $event.complete"
          >
            <template slot-scope="slotProps">
              <d-button
                v-if="!invalid"
                :text="'general.actions.save'"
                :disabled="!ibcompleted"
                class="d-btn-sm mt-3 font-text-title d-btn btn d-btn-danger font-text-title pull-right"
                @on:button-click="payByIban(slotProps.elements)"
              />
              <d-button
                v-else
                :text="'general.actions.save'"
                class="d-btn-sm mt-3 font-text-title d-btn btn d-btn-danger font-text-title pull-right disabled"
              />
            </template>
          </stripe-element>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import {StripeElement} from "vue-stripe-better-elements";

export default {
  components: {
    StripeElement,
  },
  data: () => (
    {
      validation: require('@validation/entities/doinsport/Stripe.json'),
      IbanFullName: null,
      IbanEmail: null,
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      ibanOptions: {
        supportedCountries: [`SEPA`],
      },
      ibcompleted: false,
      cdcompleted: false
    }
  ),
  props: {
    IbanEmail: {
      type: String,
      default: '',
    },
    IbanFullName: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currency() {
      return this.$store.getters["auth/currentClub"].currency;
    },
    stripeAccountReference() {
      return this.$store.getters["auth/currentClub"].stripeAccountReference;
    },
  },
  watch: {
    show: function (changes) {
      this.$bvModal.show("iban-modal-id");
    },
    hide: function (changes) {
      this.hideModal();
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("iban-modal-id");
    },
    payByIban({createSource}) {
      try {
        createSource({
          type: "sepa_debit",
          currency: this.currency,
          owner: {
            name: this.IbanFullName,
            email: this.IbanEmail,
          },
          mandate: {
            notification_method: "email"
          }
        })
          .then((response) => {
            if ("undefined" !== typeof response.error) {
              this.$flash(null, response.error.message, 3000);
            } else {
              this.hideModal();
              this.$emit('on:save', response);
            }
          })
          .catch((error) => {
            this.$flash(null, error, 3000);
          });
      } catch (error) {
        this.$flash(null, null, 3000);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/subscriptions/_iban-modal.scss";
</style>
